@import "@ingka/core/style.scss";

/* from ikea-ux */

.ikea-container {
  display: block;
  margin: auto;
  padding: 0.5rem;
  max-width: 90rem;

  @media all and (min-width: 48rem) {
    padding: 0 0.75rem;
  }

  @media all and (min-width: 75rem) {
    padding: 0 3rem;
  }
}

.ikea-logo {
  width: 5rem;
  height: auto;
  margin-right: 0.25rem;

  @media (min-width: 36rem) {
    width: 6.25rem;
    margin-right: 0.5rem;
  }
}

.full-page-message {
  max-width: 25rem;
  margin: 0 auto;
  text-align: center;
  padding: 0 1rem;
}

table thead th {
  border-bottom: 1px solid #333;
}

li {
  margin-bottom: 0.5rem;
}

.list--plain {
  list-style: none;
  margin-left: 0;

  li {
    padding-left: 0;
  }
}

.link-button,
.link-button:hover {
  text-decoration: none;
}

h2 {
  margin-bottom: 1.25rem;
}

h3 {
  margin-bottom: 1rem;
}

.accordion__content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

/* end from ikea-ux */

svg {
  width: 100%;
  height: 100%;
}

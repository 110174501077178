.login-form-container {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.sumbit-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 24px 0 0 0;
}

.simulation-container {
  width: 100%;
  @media screen and (max-width: 750px) {
    padding-left: 8px;
  }
  @media screen and (max-width: 575px) {
    button {
      width: 100%;
    }
  }
}

.support-header {
  margin-top: 24px;
}

.support-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  width: 100%;
  max-width: 750px;
  margin: 8px 0px;
  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    article:not(:last-child) {
      max-width: 100%;
    }
    article:last-child {
      max-width: 100%;
    }
  }

  article {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    border: 2px solid black;
    border-radius: 4px;
    .contact-button {
      margin-top: auto;
    }
  }
  p {
    margin-bottom: 16px;
    padding: 0;
  }
}

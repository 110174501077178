@import "@ingka/variables/style.scss";

.breadcrumb-wrapper {
  display: flex;
  margin: 0 0 0 16px;
  align-items: center;
}

.breadcrumb:not(:first-child)::before {
  padding: 0 4px 0 2px;
  color: $colour-neutral-7;
  content: "/";
}

.breadcrumb.link {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  font-size: 100%;
  font-weight: 700;
  padding: 0 2px 0 2px;
  text-decoration: none;
  color: $colour-neutral-7;
  &:hover,
  &:visited:hover {
    text-decoration: none;
    color: $colour-static-ikea-brand-blue;
    cursor: pointer;
  }
}

.breadcrumb.text {
  display: flex;
  align-items: center;
  font-size: 100%;
  font-weight: 700;
  color: $colour-neutral-7;
  cursor: pointer;
  p {
    margin: 2px 0 0 0;
    border-bottom: 2px solid $colour-static-ikea-brand-blue;
  }
}

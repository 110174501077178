@import "@ingka/variables/style.scss";

.header-link {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  margin: 24px 8px 24px 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: $colour-neutral-7;
  font-weight: $font-weight-bold;
  border: 0px;
  &:hover,
  &:visited:hover {
    color: $colour-static-ikea-brand-blue;
    cursor: pointer;
  }
  &:visited {
    color: $colour-neutral-7;
  }
  @media screen and (max-width: 750px) {
    transition-property: box-shadow, background-color, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
    margin: 4px 4px 4px 4px;
    border-radius: 4px;
    background-color: $colour-neutral-2;
    padding: 0 0 0 8px;
    &:hover,
    &:visited:hover {
      color: #3379b5;
      background-color: white;
      box-shadow: 0 0px 1px 2px #3379b5;
    }
  }
}

.header-link.active {
  margin-top: 26px;
  @media screen and (max-width: 750px) {
    margin-top: 4px;
  }
}

.header-wrapper {
  display: flex;
  width: 100%;
  @media screen and (max-width: 750px) {
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px 8px 8px;
  }
}

.header-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.mobile-header {
  display: none;
  @media screen and (max-width: 750px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.mobile-menu-container {
  display: none;
  border: 0px;
  background-color: white;
  cursor: pointer;
  @media screen and (max-width: 750px) {
    display: flex;
  }
  svg {
    display: none;
    width: 40px;
    @media screen and (max-width: 750px) {
      display: flex;
    }
  }
}

.header-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 750px) {
    display: none;
  }
}

.header-links.active {
  @media screen and (max-width: 750px) {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    box-shadow: 0 0 2px 2px $colour-neutral-4;
    background-color: $colour-neutral-2;
    border: 1px solid $colour-neutral-2;
    border-radius: 4px;
    padding: 8px 8px 8px 16px;
    z-index: 5;
    display: flex;
    flex-direction: column;
  }
}

.header-link.auth {
  display: none;
  @media screen and (max-width: 750px) {
    display: flex;
    &:hover {
      color: #3379b5;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 750px) {
    display: none;
  }
}

.user-info-paragraph {
  font-weight: 700;
  display: block;
  word-wrap: break-word;
  overflow: hidden;
  margin: 2px 16px 0 0;
  border-bottom: 2px solid $colour-static-ikea-brand-blue;
}

.hamburger-icon {
  display: block;
  div {
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: $colour-neutral-7;
    transition: all 0.2s ease;
  }
  .toggle.line1 {
    transform: rotate(45deg) translate(5px, 6px);
  }
  .toggle.line2 {
    opacity: 0;
  }
  .toggle.line3 {
    transform: rotate(135deg) translate(-5px, 6px);
  }
}
